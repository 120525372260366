import React from "react";
import Container from "@components/Container";
import styled from "styled-components";
import Image from "@components/Image";
import PostNavigation from "./components/PostNavigation/PostNavigation";

const PostViewWrapper = styled.div`
  max-width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding-bottom: 100px;
  padding-top: 50px;
`;

const PostViewTitle = styled.h1``;
const PostViewImage = styled.div`
  margin: 40px 0;
`;

const PostViewDate = styled.div``;

const PostViewContent = styled.div`
  font-size: 17px;
  line-height: 32px;
  p {
    color: rgba(41, 41, 41, 1);
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 5px;
  }
`;
interface PostViewProps {
  content: any;
  frontmatter: any;
  pageContext: any;
  timeToRead: any;
}

const PostView: React.FC<PostViewProps> = ({
  content,
  frontmatter,
  pageContext,
  timeToRead,
}) => {
  return (
    <Container>
      <PostViewWrapper>
        <PostViewTitle>{frontmatter?.title}</PostViewTitle>
        <PostViewImage>
          <Image
            src={frontmatter?.main_image?.childImageSharp}
            alt="main-image"
          />
        </PostViewImage>
        <PostViewDate>
          {frontmatter?.createdat} - {timeToRead} min read
        </PostViewDate>
        <PostViewContent
          dangerouslySetInnerHTML={{ __html: content }}
        />

        <PostNavigation
          prev={pageContext?.prev}
          next={pageContext?.next}
        />
      </PostViewWrapper>
    </Container>
  );
};

export default PostView;
