import React from "react";
import { PageProps, graphql } from "gatsby";

import Layout from "../ui/layout";
import PostView from "@views/PostView";
import ConsultationDialog from "@components/ConsultationDialog";

const TemplateViewPage = (props: any) => {
  const frontmatter = props?.data?.markdownRemark?.frontmatter;
  const content = props?.data?.markdownRemark?.html;
  const timeToRead = props?.data?.markdownRemark?.timeToRead;
  const pageContext = props?.pageContext;

  return (
    <Layout
      seo={{
        title: frontmatter?.seo?.title || frontmatter?.title,
        description:
          frontmatter?.seo?.description || frontmatter?.description,
        image: frontmatter?.main_image?.childImageSharp?.fluid?.src,
      }}
      header={{ color: "black" }}
    >
      <PostView
        timeToRead={timeToRead}
        content={content}
        frontmatter={frontmatter}
        pageContext={pageContext}
      />
      <ConsultationDialog />
    </Layout>
  );
};

export const query = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      rawMarkdownBody
      timeToRead
      fields {
        slug
      }
      frontmatter {
        seo {
          title
          description
        }
        title
        createdat(formatString: "dddd DD MMMM YYYY")
        description
        main_image {
          childImageSharp {
            gatsbyImageData(width: 560, quality: 100)
          }
        }
      }
    }
  }
`;

export default TemplateViewPage;
